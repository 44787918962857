import React from 'react'
import styles from './Spinner.module.css'

const Spinner = props => {
  if (props.loading) {
    return <div className={styles.loading} />
  } else {
    return <div className={styles.spinner} />
  }
}

export default Spinner
