import React from 'react'
import Spinner from '../../components/Spinner/Spinner'
import styles from './Loading.module.css'

const getLoadingStyle = bg => {
  if (!bg || bg === 'fromWhite') {
    return styles.loadingPrimary
  } else if (bg === 'fromGray') {
    return styles.loadingFromGray
  }
}

const Loading = props => (
  <div className={getLoadingStyle(props.bg)}>
    <div className={styles.wrapper}>
      <Spinner />
    </div>
  </div>
)

export default Loading
