import Prismic from 'prismic-javascript'

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_ALL_ARTICLES_REQUEST = 'articles/FETCH_ALL_ARTICLES_REQUEST'
export const FETCH_ALL_ARTICLES_SUCCESS = 'articles/FETCH_ALL_ARTICLES_SUCCESS'
export const FETCH_ALL_ARTICLES_ERROR = 'articles/FETCH_ALL_ARTICLES_ERROR'

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = []

// ------------------------------------
// Actions
// ------------------------------------

export const fetchAllArticlesRequest = (loading = true) => {
  return {
    type: FETCH_ALL_ARTICLES_REQUEST,
    payload: {
      loading
    }
  }
}

export const fetchAllArticlesSuccess = (data = [], total_pages = 1) => {
  return {
    type: FETCH_ALL_ARTICLES_SUCCESS,
    payload: {
      data,
      total_pages,
      receivedAt: new Date().toISOString()
    }
  }
}

export const fetchAllArticlesError = (errorMessage = '') => {
  return {
    type: FETCH_ALL_ARTICLES_ERROR,
    payload: {
      errorMessage,
      receivedAt: new Date().toISOString()
    }
  }
}

// ------------------------------------
// Specialized Actions
// ------------------------------------

export const fetchAllArticles = pageNumber => {
  return (dispatch, getState) => {
    dispatch(fetchAllArticlesRequest(true))
    const apiEndpoint = 'https://halfmachine.prismic.io/api/v2'

    let page = pageNumber || 1

    return Prismic.api(apiEndpoint).then(api => {
      api
        .query(Prismic.Predicates.at('document.type', 'article'), {
          page: page,
          pageSize: 10,
          orderings: '[document.first_publication_date desc]'
        })
        .then(response => {
          if (response) {
            dispatch(fetchAllArticlesSuccess(response.results, response.total_pages))
            dispatch(fetchAllArticlesRequest(false))
            // console.log(response)
          }
        })
    })
  }
}

// export const fetchAllArticles = pageNumber => {
//   return async (dispatch, getState) => {
//     dispatch(fetchAllArticlesRequest(true))
//     const apiEndpoint = 'https://halfmachine.prismic.io/api/v2'

//     let page = pageNumber || 1

//     try {
//       const users = await axios
//         .get(`https://jsonplaceholder.typicode.com/users`)
//         .then(res => res.data)

//       dispatch({
//         type: types.FETCH_USERS,
//         payload: users
//       })
//     } catch (err) {
//       dispatch({
//         type: types.UPDATE_ERRORS,
//         payload: [
//           {
//             code: 735,
//             message: err.message
//           }
//         ]
//       })
//     }
//   }
// }

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ARTICLES_REQUEST:
      return { ...state, ...action.payload }

    case FETCH_ALL_ARTICLES_SUCCESS:
      return { ...state, ...action.payload }

    case FETCH_ALL_ARTICLES_ERROR:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
