import React from 'react'
import Subtitle from './Subtitle'
import Title from './Title'
import styles from './Hero.module.css'
import Loading from '../../../sections/Loading/Loading'

const Hero = props => (
  <div className={styles.heroWrapper}>
    <Loading />
    <div
      data-aos='fade-down'
      data-aos-duration='2000'
      data-aos-delay='2000'
      className={styles.hero}
    >
      <Subtitle />
      <Title />
    </div>
  </div>
)

export default Hero
