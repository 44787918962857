import React from 'react'
import Hero from './Hero/Hero'
import KeyMessage from './KeyMessage/KeyMessage'
import GetInTouch from '../../sections/GetInTouch/GetInTouch'
import CardSection from '../../sections/CardSection/CardSection'
import Services from '../../sections/ImageCards/Section'
import Articles from '../../sections/Articles/Articles'
import pink from './pink-small.jpg'
import man from './man2-small.jpg'
import blue from './blue-small.jpg'
import PageArticle from '../../components/PageArticle/PageArticle'

const services = [
  {
    title: 'Innovation consulting',
    subtitle:
      'Removing constraints that inhibit significant advancement by combining human centered design with technology.',
    image: pink,
    link: '/services#innovation-consulting'
  },
  {
    title: 'Behaviour design',
    subtitle:
      'Using principles of psychology and behavioural economics to get to the core of what motivates humans within given contexts.',
    image: man,
    link: '/services#behaviour-design'
  },
  {
    title: 'Service & Product design',
    subtitle:
      'Where the impact of technology on business problems & a deep understanding of humans become tangible.',
    image: blue,
    link: '/services#service-product-design'
  }
]

const sandbox = {
  header: 'Half Machine Sandbox',
  text:
    'The best way to learn something is to do the research but then start to prototype. That way we get a sense of the dimensionality of the technology we are using and an understanding of whats possible within a given context.\n' +
    "Half Machine Sandbox is where we can experiment with technology without some of the usual constraints. Its a place where we can connect user desirability to a technological solution without having to think about legacy. It's also where we collaborate with startups and exchange knowledge.\n" +
    'Find out how we can build a Sandbox for your organisation.',
  anchor: 'half-machine-sandbox',
  image: 'alternative'
}

const sprints = {
  header: 'Design sprints - A new way of working',
  text:
    'Employing a product design methodology for innovation helps teams ship outputs faster that are inherently user centred.\n' +
    'The act of immersing the team in the world of the user prior to the sprint then coming together to focus on solutions is hugely productive. Resulting in an abundance of ideas followed by working protoypes to be tested with real users.\n',
  anchor: 'design-sprints-for-innovation',
  image: 'sprints',
  cta: {
    to: '/design-sprints',
    text: 'Explore sprints'
  },
  info: 'Some tools we use',
  links: [
    {
      text: 'Contextual inquiry',
      href: '/case-studies#design-sprints'
    },
    {
      text: 'Jobs to be done',
      href: '/case-studies#psfk-watson'
    },
    {
      text: 'Journey mapping',
      href: '/case-studies#brainchild'
    },
    {
      text: 'Clue extraction',
      href: '/case-studies#cora-bot'
    },

    {
      text: 'Random links',
      href: '/services#innovation-consulting'
    },
    {
      text: 'User testing',
      href: '/services#behaviour-design'
    }
  ]
}

const cora = {
  header: 'Cora - The injury recovery bot',
  addition: 'Case study',
  text:
    'Of the half a million or so Australians hospitalised as a result of traumatic injury, one-in-three* will experience depression after three months. How might we reduce this?\n' +
    'Cora is a community based support platform who’s ultimate vision is to provide scalable support via an AI driven chat application.\n' +
    'Cheerleader, counsellor and coach, Cora is designed to help users master their recovery by delivering simple, easy to follow coping strategies accessible anywhere, anytime.',
  anchor: 'cora',
  image: 'cora',
  cta: {
    to: '/case-studies',
    text: 'Case studies'
  },
  links: [
    {
      text: 'Design sprints',
      href: '/case-studies#design-sprints'
    },
    {
      text: 'Cora bot',
      href: '/case-studies#cora-bot'
    },
    {
      text: 'PSFK Watson',
      href: '/case-studies#psfk-watson'
    },
    {
      text: 'Brainchild',
      href: '/case-studies#brainchild'
    }
  ]
}

// 50% chance of seeing cora or sandbox

const getCardSection = () => {
  if (Math.random() >= 0.5) {
    return sandbox
  } else {
    return cora
  }
}

const cs = getCardSection()

const Home = () => {
  return (
    <PageArticle
      pageName='home'
      render={(recentArticles, pageArticles, loading) => (
        <>
          <Hero />
          <CardSection
            image={sprints.image}
            header={sprints.header}
            addition={sprints.addition}
            text={sprints.text}
            anchor={sprints.anchor}
            cta={sprints.cta}
            links={sprints.links}
            info={sprints.info}
          />
          <KeyMessage />
          <Services data={services} header='Our services' cta />
          <CardSection
            image={cs.image}
            header={cs.header}
            addition={cs.addition}
            text={cs.text}
            anchor={cs.anchor}
            cta={cs.cta}
            links={cs.links}
          />
          <Articles
            title="What's on our mind"
            padV
            recentArticles={recentArticles}
            pageArticles={pageArticles}
            loading={loading}
          />
          <GetInTouch />
        </>
      )}
    />
  )
}

export default Home
