import React from 'react'
import Banner from '../../components/ContentBanner/Banner'
import styles from './CardSection.module.css'

const getImageClassName = (image) => {
  if (image === 'alternative') {
    return styles.approachAlternative
  } else if (image === 'cora') {
    return styles.approachCora
  } else if (image === 'sprints') {
    return styles.approachSprints
  } else {
    return styles.approachDefault
  }
}

const CardSection = ({
  image,
  header,
  text,
  anchor,
  addition,
  cta,
  links,
  info
}) => (
  <>
    <span className={styles.anchor} id={anchor} />
    <div
      data-aos='fade-up'
      data-aos-duration='2000'
      className={getImageClassName(image)}
      id='half-machine-sandbox'
    >
      <div className={styles.wrapper}>
        <Banner
          image={image}
          header={header}
          text={text}
          addition={addition}
          cta={cta}
          links={links}
          info={info}
        />
      </div>
    </div>
  </>
)

export default CardSection
