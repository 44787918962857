import React from 'react'
import { title, accent } from './Title.module.css'

const Title = () => (
  <h1 className={title}>
    The <span className={accent}>future</span> won't build itself. It requires
    people with <span className={accent}>imagination</span> and a{' '}
    <span className={accent}>willingness</span> to do the hard work to make
    great <span className={accent}>forward thinking</span> ideas{' '}
    <span className={accent}>come to life</span>.
  </h1>
)

export default Title
