import React from 'react'
import styles from './FOF.module.css'
import CTA from '../../components/CTA/CTA'

const FOF = props => (
  <div className={styles.container}>
    <div className={styles.fof}>
      <h2 className={styles.title}>Error spotted.</h2>
      <h5 className={styles.subtitle}>
        Unfortunately the page you are looking for can't be found anywhere on
        the server. Would you like to go to the homepage instead?
      </h5>
      <div className={styles.ctaWrapper}>
        <CTA to='/' text='Go to homepage' />
      </div>
    </div>
  </div>
)

export default FOF
