import React from 'react'
import styles from './Image.module.css'
import Media from 'react-media'

const getImage = (matches, image, imageSmall) => {
  if (imageSmall && !matches) {
    return imageSmall
  } else {
    return image
  }
}

const Image = ({ image, imageSmall, title }) => (
  <Media query='(min-width: 576px)'>
    {matches =>
      <img src={getImage(matches, image, imageSmall)} alt={title} className={styles.image} />
    }
  </Media>
)

export default Image
