import React from 'react'
import styles from './Feedback.module.css'
import feedbacks from './feedbacks/feedbacks'

const data = feedbacks[Math.floor(Math.random() * 3)]

const Feedback = () => (
  <div className={styles.wrapper}>
    <div className={styles.feedback}>
      <div className={styles.innerContent}>
        <img
          className={styles.image}
          src={data.avatar}
          alt='Joe Bramwell-Smith'
        />
        <p className={styles.message}>{data.content}</p>
        <p className={styles.who}>{data.name}</p>
        <p className={styles.position}>{data.role}</p>
      </div>
    </div>
  </div>
)

export default Feedback
