import React, { Fragment } from 'react'
import GetInTouch from '../../sections/GetInTouch/GetInTouch'
import SmallHero from '../../sections/SmallHero/SmallHero'
import ContentBlock from '../../sections/ContentBlock/Block'
import Articles from '../../sections/Articles/Articles'
import pink from './pink.png'
import men from './men.png'
import blue from './blue.png'
import PageArticle from '../../components/PageArticle/PageArticle'
import CardSection from '../../sections/CardSection/CardSection'

const links = [
  {
    text: 'Innovation consulting',
    href: '#innovation-consulting'
  },
  {
    text: 'Behaviour design',
    href: '#behaviour-design'
  },
  {
    text: 'Service & Product design',
    href: '#service-product-design'
  },
  {
    text: 'Half Machine Sandbox',
    href: '#half-machine-sandbox'
  }
]

const cs = {
  header: 'Half Machine Sandbox',
  text:
    'The best way to learn something is to do the research but then start to prototype. That way we get a sense of the dimensionality of the technology we are using and an understanding of whats possible within a given context.\n' +
    "Half Machine Sandbox is where we can experiment with technology without some of the usual constraints. Its a place where we can connect user desirability to a technological solution without having to think about legacy. It's also where we collaborate with startups and exchange knowledge.\n" +
    'Find out how we can build a Sandbox for your organisation.',
  anchor: 'half-machine-sandbox'
}

const cb1 = {
  type: 'summary',
  title: 'Innovation consulting',
  summary: {
    title: 'Service summary',
    content:
      'Applying transformal technology to business problems through a human centered lens.',
    addition: {
      title: 'For those looking for',
      items: [
        'Problem identification & mapping',
        'Focused design sprints',
        'Innovation roadmapping'
      ]
    }
  },
  text:
    'Removing constraints that inhibit significant advancement by combining human centered design with new technology.\n' +
    'Innovation consulting today largely centers around the application of new technologies to the current or evolving business model. The physical application of these new technologies requires two common approaches being the designing of the experience from the viewpoint of the user and the staged implementation of the technology given the inherent associated complexity.\n' +
    'The result is the removal of constraints that currently inhibit the growth of the business or the realisation of new value by tapping into newly understood or understood unmet needs.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: pink,
  anchor: 'innovation-consulting'
}

const cb2 = {
  type: 'summary',
  title: 'Behaviour design',
  summary: {
    title: 'Service summary',
    content:
      'Distilling the key factors in motivating valuable user behaviours.',
    addition: {
      title: 'Useful for',
      items: [
        'Understanding first principles of behaviour',
        'Human component of business problems',
        'Formulating cross project insights'
      ]
    }
  },
  text:
    'Using principles of psychology and behavioural economics to get to the core of what motivates humans within given contexts.\n' +
    'Before we design a product or system focused on driving a specific behaviour we need to understand the nuances of the users motivations within the given context. Typically these centre around belonging and the need to express identity or reinforce social connection and hierarchy, but can also be as simple as wanting to complete a job to be done in their lives in order to fulfill basic needs.\n' +
    'We then dive into the cognitive constraints that may affect the human factors of interaction or decision making in this context. In order to make our experience as rewarding as possible or just easy from a cognitive load point of view.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: men,
  anchor: 'behaviour-design'
}

const cb3 = {
  type: 'summary',
  title: 'Service & Product design',
  summary: {
    title: 'Service summary',
    content:
      'A highly detailed approach to feature development & design.',
    addition: {
      title: 'For those looking for',
      items: [
        'Highly nuanced solutions',
        'Exhaustive mapping & service fit',
        'Products that drive habitual behaviour'
      ]
    }
  },
  text:
    'Where the impact of technology on business problems & a deep understanding of humans become tangible.\n' +
    "From the perspective of the adoption of new technologies service or product design takes on the shape of prototyping and constrained feature sets in order to test specific hypotheses. This process undoubtedly circles back to the behaviour design phase where we attempt to address assumptions in our designs but the beauty of this is that it isn't linear. In it's contact with users it forces us to sometimes completely rethink what we thought we knew.\n" +
    'In this sense the process is never complete, but what we do get to is a solid starting point that allows us to move in a highly informed direction from the beginning.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: blue,
  anchor: 'service-product-design'
}

const Services = () => {
  return (
    <PageArticle
      pageName='services'
      render={(recentArticles, pageArticles, loading) => (
        <Fragment>
          <SmallHero
            title='Services'
            subtitle={
              'Designing conflict out of Human and Computer interaction.'
            }
            links={links}
          />
          <ContentBlock
            type={cb1.type}
            title={cb1.title}
            summary={cb1.summary}
            text={cb1.text}
            cta={cb1.cta}
            image={cb1.image}
            anchor={cb1.anchor}
          />
          <ContentBlock
            type={cb2.type}
            title={cb2.title}
            summary={cb2.summary}
            text={cb2.text}
            cta={cb2.cta}
            image={cb2.image}
            anchor={cb2.anchor}
          />
          <ContentBlock
            type={cb3.type}
            title={cb3.title}
            summary={cb3.summary}
            text={cb3.text}
            cta={cb3.cta}
            image={cb3.image}
            anchor={cb3.anchor}
          />
          <CardSection
            image='alternative'
            header={cs.header}
            text={cs.text}
            anchor={cs.anchor}
          />
          <Articles
            title='Related articles'
            padV
            recentArticles={recentArticles}
            pageArticles={pageArticles}
            loading={loading}
          />
          <GetInTouch />
        </Fragment>
      )}
    />
  )
}

export default Services
