import React from 'react'
import styles from './KeyMessage.module.css'
import './animation.css'
import CTA from '../../../components/CTA/CTA'

const KeyMessage = props => (
  <div
    data-aos='fade-up'
    data-aos-duration='2000'
    data-aos-offset='0'
    className={`${styles.keyMessage} keyMessageAnimation`}
  >
    <div className={styles.gridWrapper}>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>
          Human<br />meets<br />machine
        </h2>
        <p className={styles.text}>
          Technology is a tool for the ultimate benefit of people, businesses and organisations. In order for this to be fully realised, technology needs to be designed to meet well understood needs, motivations and human decision making characteristics.
        </p>
        <CTA to='/vision' text='Our vision' main='secondaryText' dot='accent' />
      </div>
    </div>
  </div>
)

export default KeyMessage
