import React, { Component } from 'react'
import Nav from './Nav'
import NavContent from './Content'
import styles from './Navigation.module.css'
import { connect } from 'react-redux'
import { open, close } from '../../store/modules/menu.js'

class Navigation extends Component {
  handleOpen = active => {
    const { open, close } = this.props
    active ? open() : close()
  }

  render () {
    const { opened } = this.props
    if (opened) {
      return (
        <div className={styles.opened}>
          <Nav opened={opened} onClick={this.handleOpen} />
          <NavContent />
        </div>
      )
    } else {
      return (
        <div
          className={styles.closed}
        >
          <Nav opened={opened} onClick={this.handleOpen} />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  opened: state.menu.opened
})

const mapDispatchToProps = {
  open,
  close
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
