import React from 'react'
import styles from './Tools.module.css'

const Tools = ({ tools }) => (
  <div className={styles.wrapper} id='use-cases'>
    <div className={styles.tools}>
      <div className={styles.innerContent}>
        {tools.map(({ title, content, icon }) => (
          <div key={title} className={styles.tool}>
            <div className={styles.iconWrapper}>
              <img src={icon} alt={title} className={styles.icon} />
            </div>
            <p className={styles.title}>{title}</p>
            <p className={styles.content}>{content}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Tools
