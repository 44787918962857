import React, { Fragment } from 'react'
import GetInTouch from '../../sections/GetInTouch/GetInTouch'
import SmallHero from '../../sections/SmallHero/SmallHero'
import ContentBlock from '../../sections/ContentBlock/Block'
// import ImageCards from '../../sections/ImageCards/Section'
import Articles from '../../sections/Articles/Articles'
import culture from './culture-new.png'
// import james from './james-headshot.png'
// import damien from './damien-headshot.png'
// import Accordion from '../../components/Accordion/Accordion'
// import accord from './accord.png'
import PageArticle from '../../components/PageArticle/PageArticle'

const links = [
  // {
  //   text: 'Leadership',
  //   href: '#leadership'
  // },
  {
    text: 'Our culture',
    href: '#our-culture'
  },
  // {
  //   text: 'Work with us',
  //   href: '#work-with-us'
  // }
]

const cb1 = {
  type: 'simple',
  title: 'Culture',
  subtitle:
    'Half Machine does not have a culture; Half Machine is a culture.',
  text:
  'Our culture is the foundation of our company and driving force behind our purpose" to create \'Technology that Enhances the Human Experience\'. Our staunch belief in diversity opens us up to seeing the world in different ways, encourages abstract thinking and allows us to explore the possibilities of the unexpected. Whilst we\'re not comfortable with conformity we love like-minded individuals: Purposeful people with a bias toward action, a mutual love of evidenced-based design, and a fundamental desire to work with others to push things forward and affect the change required to enhance our experience of the world.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: culture,
  anchor: 'culture'
}

// const people = [
//   {
//     title: 'Damien Hughes',
//     subtitle: 'Experience Strategy Partner',
//     content:
//       'Since leaving university where he studied Psychology & Statistics, Damien has spent the last 15 years working with enterprises, as well as with and in startups in a strategy, research, product design and product management capacity. He brings a solid understanding of technology, user centred design and business strategy to the management team.',
//     link: 'https://www.linkedin.com/in/hughesdamien/',
//     image: damien
//   },
//   {
//     title: 'James Theophane',
//     subtitle: 'Managing Partner',
//     content:
//       'Noted for his "forward thinking, intelligent ideas that have a stake firmly in the real world", James has spent the last 20 years tinkering with digital products, ideas and transformational strategy. He has received top honours in product design for Humanitarian Aid, featured in WARC100\'s "100 smartest marketing campaigns in the world", and picked up the coveted D&AD \'Creativity for Good\' Yellow Pencil and Gold Cannes Lions for Innovation.',
//     link: 'https://www.linkedin.com/in/iamtheo/',
//     image: james
//   }
// ]

/*
const accordion = {
  title: 'Work with us',
  anchor: 'work-with-us',
  items: [
    {
      name: 'Senior UI designer',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem, luctus ut mi eget, consectetur cursus turpis. Phasellus molestie nulla sed aliquet egestas. Phasellus purus leo, viverra ut ligula sit amet, auctor laoreet nulla. Suspendisse luctus turpis odio, at tempor mi pellentesque quis. In hac habitasse platea dictumst. Etiam a sodales neque. Sed nulla quam, consequat ut dui vel, laoreet sodales ligula.\n' +
        'Vestibulum in molestie velit. Donec ac maximus odio. Suspendisse potenti. Donec maximus pharetra gravida. Vivamus lobortis tempus turpis, sed pulvinar quam aliquet ut. Aenean gravida turpis tortor, eget tincidunt turpis rhoncus elementum. Curabitur at lacus mauris. Praesent sit amet pharetra turpis, et ornare sem. In eget dapibus purus. Duis fringilla fringilla purus, non tristique neque elementum a. Vivamus tincidunt nunc mollis dolor ultricies, ac sollicitudin quam rhoncus.',
      image: accord
    },
    {
      name: 'Behavorial Economist',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem, luctus ut mi eget, consectetur cursus turpis. Phasellus molestie nulla sed aliquet egestas. Phasellus purus leo, viverra ut ligula sit amet, auctor laoreet nulla. Suspendisse luctus turpis odio, at tempor mi pellentesque quis. In hac habitasse platea dictumst. Etiam a sodales neque. Sed nulla quam, consequat ut dui vel, laoreet sodales ligula.\n' +
        'Vestibulum in molestie velit. Donec ac maximus odio. Suspendisse potenti. Donec maximus pharetra gravida. Vivamus lobortis tempus turpis, sed pulvinar quam aliquet ut. Aenean gravida turpis tortor, eget tincidunt turpis rhoncus elementum. Curabitur at lacus mauris. Praesent sit amet pharetra turpis, et ornare sem. In eget dapibus purus. Duis fringilla fringilla purus, non tristique neque elementum a. Vivamus tincidunt nunc mollis dolor ultricies, ac sollicitudin quam rhoncus.',
      image: accord
    },
    {
      name: 'Software engineer',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem, luctus ut mi eget, consectetur cursus turpis. Phasellus molestie nulla sed aliquet egestas. Phasellus purus leo, viverra ut ligula sit amet, auctor laoreet nulla. Suspendisse luctus turpis odio, at tempor mi pellentesque quis. In hac habitasse platea dictumst. Etiam a sodales neque. Sed nulla quam, consequat ut dui vel, laoreet sodales ligula.\n' +
        'Vestibulum in molestie velit. Donec ac maximus odio. Suspendisse potenti. Donec maximus pharetra gravida. Vivamus lobortis tempus turpis, sed pulvinar quam aliquet ut. Aenean gravida turpis tortor, eget tincidunt turpis rhoncus elementum. Curabitur at lacus mauris. Praesent sit amet pharetra turpis, et ornare sem. In eget dapibus purus. Duis fringilla fringilla purus, non tristique neque elementum a. Vivamus tincidunt nunc mollis dolor ultricies, ac sollicitudin quam rhoncus.',
      image: accord
    },
    {
      name: 'Data scientist',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nulla lorem, luctus ut mi eget, consectetur cursus turpis. Phasellus molestie nulla sed aliquet egestas. Phasellus purus leo, viverra ut ligula sit amet, auctor laoreet nulla. Suspendisse luctus turpis odio, at tempor mi pellentesque quis. In hac habitasse platea dictumst. Etiam a sodales neque. Sed nulla quam, consequat ut dui vel, laoreet sodales ligula.\n' +
        'Vestibulum in molestie velit. Donec ac maximus odio. Suspendisse potenti. Donec maximus pharetra gravida. Vivamus lobortis tempus turpis, sed pulvinar quam aliquet ut. Aenean gravida turpis tortor, eget tincidunt turpis rhoncus elementum. Curabitur at lacus mauris. Praesent sit amet pharetra turpis, et ornare sem. In eget dapibus purus. Duis fringilla fringilla purus, non tristique neque elementum a. Vivamus tincidunt nunc mollis dolor ultricies, ac sollicitudin quam rhoncus.',
      image: accord
    }
  ]
}
*/

const AboutUs = () => {
  return (
    <PageArticle
      pageName='about'
      render={(recentArticles, pageArticles, loading) => (
        <Fragment>
          <SmallHero
            title='About us'
            subtitle='Delivering thinking that adds new dimensions of understanding to problems and applying ways of solving them.'
            links={links}
          />
          {/* <ImageCards data={people} transparent anchor='leadership' /> */}
          <ContentBlock
            type={cb1.type}
            title={cb1.title}
            subtitle={cb1.subtitle}
            text={cb1.text}
            cta={cb1.cta}
            image={cb1.image}
            anchor={cb1.anchor}
          />
          {/* <Accordion type='jobs' anchor='work-with-us' title='Work with us' /> */}
          <Articles
            title='Related articles'
            padV
            recentArticles={recentArticles}
            pageArticles={pageArticles}
            loading={loading}
          />
          <GetInTouch />
        </Fragment>
      )}
    />
  )
}

export default AboutUs
