import Prismic from 'prismic-javascript'

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_RECENT_ARTICLES_REQUEST =
  'articles/FETCH_RECENT_ARTICLES_REQUEST'
export const FETCH_RECENT_ARTICLES_SUCCESS =
  'articles/FETCH_RECENT_ARTICLES_SUCCESS'
export const FETCH_RECENT_ARTICLES_ERROR =
  'articles/FETCH_RECENT_ARTICLES_ERROR'

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = []

// ------------------------------------
// Actions
// ------------------------------------

export const fetchRecentArticlesRequest = (loading = true) => {
  return {
    type: FETCH_RECENT_ARTICLES_REQUEST,
    payload: {
      loading
    }
  }
}

export const fetchRecentArticlesSuccess = (data = []) => {
  return {
    type: FETCH_RECENT_ARTICLES_SUCCESS,
    payload: {
      data,
      receivedAt: new Date().toISOString()
    }
  }
}

export const fetchRecentArticlesError = (errorMessage = '') => {
  return {
    type: FETCH_RECENT_ARTICLES_ERROR,
    payload: {
      errorMessage,
      receivedAt: new Date().toISOString()
    }
  }
}

// ------------------------------------
// Specialized Actions
// ------------------------------------

export const fetchRecentArticles = () => {
  return (dispatch, getState) => {
    dispatch(fetchRecentArticlesRequest(true))
    const apiEndpoint = 'https://halfmachine.prismic.io/api/v2'

    return Prismic.api(apiEndpoint).then(api => {
      api
        .query(Prismic.Predicates.at('document.type', 'article'), {
          page: 1,
          pageSize: 3,
          orderings: '[document.first_publication_date desc]'
        })
        .then(response => {
          if (response) {
            dispatch(fetchRecentArticlesSuccess(response.results))
            dispatch(fetchRecentArticlesRequest(false))
          }
          // console.log('response', response)
        })
      // console.log('api', api)
    })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECENT_ARTICLES_REQUEST:
      return { ...state, ...action.payload }

    case FETCH_RECENT_ARTICLES_SUCCESS:
      return { ...state, ...action.payload }

    case FETCH_RECENT_ARTICLES_ERROR:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
