import React from 'react'
import style from './Banner.module.css'
import Image from './Image'
import Content from './Content'
import Subcontent from './Subcontent'
import Media from 'react-media'

const Banner = ({ image, header, text, addition, cta, links, info }) => (
  <Media query='(min-width: 576px)'>
    {(matches) =>
      matches ? (
        <div className={style.banner}>
          <Content header={header} text={text} addition={addition} cta={cta} />
          <Subcontent links={links} info={info} />
        </div>
      ) : (
        <div className={style.banner}>
          <Image image={image} />
          <Content header={header} text={text} addition={addition} cta={cta} />
          <Subcontent links={links} info={info} />
        </div>
      )}
  </Media>
)

export default Banner
