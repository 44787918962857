import React from 'react'
import styles from './Text.module.css'

const formattedText = text => {
  return text.split('\n').map((item, index) => {
    return (
      <p className={styles.text} key={item + index}>
        {item}
      </p>
    )
  })
}

const Text = ({ text }) => formattedText(text)

export default Text
