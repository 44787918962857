import React, { Fragment } from 'react'
import GetInTouch from '../../sections/GetInTouch/GetInTouch'
import SmallHero from '../../sections/SmallHero/SmallHero'
import ContentBlock from '../../sections/ContentBlock/Block'
import Articles from '../../sections/Articles/Articles'
import vision from './vision.jpg'
import PageArticle from '../../components/PageArticle/PageArticle'

const links = [
  {
    text: 'Our vision',
    href: '#vision'
  }
]
const cb1 = {
  type: 'simple',
  title: 'Our vision',
  subtitle:
    'We believe creating technology products requres a deep understanding of human behaviour and cognition, coupled with dedicated design and experimentation methodologies.',
  text:
    'We believe that technology has the ability to enhance the human experience. However, core to the realisation of this ability is a deep understanding of human beings and the proper design of systems to which the technology is a central part.\n' +
    'The challenge in this mission is not only utilising technology in order to help people fulfill a specific job to be done but to zoom out and observe the peripheral effects this may have. It is an ambitious task given the orders of magnitude of complexity this invites but one we feel to be a very worthy one if technology is to reach its ultimate status as a tool for the benefit of humanity.',
  cta: {
    to: '/services',
    text: 'See our services'
  },
  image: vision,
  anchor: 'vision'
}

const Vision = () => {
  return (
    <PageArticle
      pageName='vision'
      render={(recentArticles, pageArticles, loading) => (
        <Fragment>
          <SmallHero title='Vision' links={links} />
          <ContentBlock
            type={cb1.type}
            title={cb1.title}
            subtitle={cb1.subtitle}
            text={cb1.text}
            cta={cb1.cta}
            image={cb1.image}
            anchor={cb1.anchor}
          />
          <Articles
            title='Related articles'
            padV
            recentArticles={recentArticles}
            pageArticles={pageArticles}
            loading={loading}
          />
          <GetInTouch />
        </Fragment>
      )}
    />
  )
}

export default Vision
