import React, { Fragment } from 'react'
import styles from './List.module.css'
import Spinner from '../../components/Spinner/Spinner'
import { Link } from 'react-router-dom'

const renderLoading = () => {
  return (
    <div className={styles.loading} data-aos='fade-up' data-aos-duration='2000'>
      <Spinner loading />
    </div>
  )
}

const formattedArticles = articles => {
  return articles.map(item => {
    return (
      <li key={item.data.title} className={styles.itemWrapper}>
        <a
          href={item.data.link.url}
          rel='noopener noreferrer'
          target={item.data.link.target}
          className={styles.item}
        >
          <span>-</span> <strong>{item.data.title}</strong> by{' '}
          {item.data.author}
        </a>
      </li>
    )
  })
}

const renderNextPrev = (pageID, totalPages) => {
  const pageIDNum = parseInt(pageID)
  const totalPagesNum = parseInt(totalPages)
  const shouldPrevious = !(pageIDNum === 1)
  const shouldNext = !(pageIDNum === totalPagesNum)
  if (pageIDNum === 1 && totalPagesNum === 1) {
    return null
  }
  return (
    <Fragment>
      {shouldPrevious && (
        <Link to={`/articles/${pageIDNum - 1}`} className={styles.previous}>
          Previous
        </Link>
      )}
      <span className={styles.pages}>
        {pageID} / {totalPages}
      </span>
      {shouldNext && (
        <Link to={`/articles/${pageIDNum + 1}`} className={styles.next}>
          Next
        </Link>
      )}
    </Fragment>
  )
}

const renderGoFirst = () => {
  return (
    <Fragment>
      <p>You provided wrong article page number</p>
      <Link to={`/articles/1`} className={styles.next}>
        Go to the first page
      </Link>
    </Fragment>
  )
}

const List = ({ articles, loading, pageID, totalPages }) => {
  if (loading) {
    return renderLoading()
  } else if (pageID > totalPages) {
    return <Fragment>{renderGoFirst()}</Fragment>
  } else if (articles) {
    return (
      <Fragment>
        <ul className={styles.list}>{formattedArticles(articles)}</ul>
        {renderNextPrev(pageID, totalPages)}
      </Fragment>
    )
  } else {
    return renderLoading()
  }
}

export default List
