import React from 'react'
import styles from './SmallHero.module.css'
import Loading from '../Loading/Loading'
import { HashLink as Link } from 'react-router-hash-link'

const generateLinks = links => {
  if (!links) {
    return null
  } else {
    const generatedLinks = links.map(link => (
      <li key={link.href} className={styles.link}>
        <Link to={link.href}>{link.text}</Link>
      </li>
    ))
    return <ul className={styles.links}>{generatedLinks}</ul>
  }
}

const generateSubtitle = subtitle => {
  if (subtitle) {
    return <p className={styles.subtitle}>{subtitle}</p>
  } else {
    return (
      <p className={styles.subtitle}>
        Humans will beat machines some of the time.
        Machines will beat humans most of the time.
        Humans working with machines will beat machines all the time.
      </p>
    )
  }
}

const SmallHero = props => (
  <div className={styles.wrapper}>
    <Loading bg='fromGray' />
    <div
      data-aos='fade-down'
      data-aos-duration='2000'
      data-aos-delay='2000'
      className={styles.smallHero}
    >
      <h1 className={styles.title}>{props.title}</h1>
      {generateSubtitle(props.subtitle)}
      {generateLinks(props.links)}
    </div>
  </div>
)

export default SmallHero
