import joe from './joe.png'
import nick from './nick.jpg'
import leo from './leo.jpg'

const feedbacks = [
  {
    name: 'Joe Bramwell-Smith',
    role: 'Co-founder, Kitfit',
    content:
      'With Half Machine we were able to focus our feature set and get invaluable feedback from users in a very short space of time.',
    avatar: joe
  },
  {
    name: 'Leo Roberts',
    role: 'Group Marketing Manager, The Coca-Cola Company',
    content:
      'The design sprints we’ve done with Half Machine are routinely held up as examples of how we’ve worked efficiently and effectively at Coca Cola.',
    avatar: leo
  },
  {
    name: 'Nick Cloete',
    role: 'CEO, Kounta',
    content:
      'The team at Half Machine are incredible product thinkers, capable of taking solutions into completely new spaces.',
    avatar: nick
  }
]

export default feedbacks
