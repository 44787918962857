import React from 'react'
import styles from './Title.module.css'

const getClassName = (titleAddition, video) => {
  if (video) {
    return styles.videoWrapper
  } else if (titleAddition) {
    return styles.additionWrapper
  }
  return styles.normalWrapper
}

const Title = ({ title, titleAddition, video, onVideoClick }) => (
  <div className={getClassName(titleAddition, video)}>
    {titleAddition && <h6 className={styles.titleAddition}>{titleAddition}</h6>}
    <h3 className={styles.title}>{title}</h3>
    {video && (
      <button className={styles.videoButton} onClick={onVideoClick}>
        <span className={styles.playButton}>
          <span className={styles.playIcon} />
        </span>
        Watch video
      </button>
    )}
  </div>
)

export default Title
