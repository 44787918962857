import React from 'react'
import styles from './Video.module.css'

const getEmbed = url => {
  const splitter = '/watch?v='
  const modifiedUrl = url.split(splitter)
  return `${modifiedUrl[0]}/embed/${modifiedUrl[1]}`
}

const Video = ({ video, onVideoClick }) => (
  <div className={styles.wrapper} onClick={onVideoClick}>
    <div className={styles.innerWrapper}>
      <span className={styles.close} onClick={onVideoClick} />
      <div className={styles.videoWrapper}>
        <iframe
          className={styles.video}
          src={`${getEmbed(video)}?autoplay=1&rel=0&amp;showinfo=0`}
          frameBorder='0'
          allowFullScreen
          title={video}
        />
      </div>
    </div>
  </div>
)

export default Video
