// ------------------------------------
// Constants
// ------------------------------------

export const OPEN = 'menu/OPEN'
export const CLOSE = 'menu/CLOSE'

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = { opened: false }

// ------------------------------------
// Actions
// ------------------------------------

export const open = () => {
  return {
    type: OPEN,
    payload: {
      opened: true
    }
  }
}

export const close = () => {
  return {
    type: CLOSE,
    payload: {
      opened: false
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN:
      return { ...state, ...action.payload }

    case CLOSE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
