import React from 'react'
import Logo from '../Logo/Logo'
import Menu from '../Menu/Menu'
import styles from './Nav.module.css'

const Nav = props => (
  <div className={styles.nav}>
    <div className={styles.logoWrapper}>
      <Logo />
    </div>
    <div className={styles.menuWrapper}>
      <Menu opened={props.opened} onClick={props.onClick} />
    </div>
  </div>
)

export default Nav
