import React from 'react'
import styles from './Sprints.module.css'

import image from './sprints.svg'

const Sprints = ({ sprints }) => (
  <div className={styles.wrapper}>
    {sprints.map(({ title, steps, present }) => (
      <div className={styles.sprint} key={title}>
        <div className={styles.header}>
          <p className={styles.title}>{title}</p>
          {present && <p className={styles.presentMobile}>Clients Present</p>}
        </div>
        <div className={styles.content}>
          {steps.map((step) => (
            <div className={styles.stepWrapper} key={step}>
              <p className={styles.step}>{step}</p>
            </div>
          ))}
        </div>
      </div>
    ))}
    <img src={image} alt='Design Sprints' className={styles.sprintsImage} />
  </div>
)

export default Sprints
