import React, { Fragment } from 'react'
import styles from './Summary.module.css'
import { HashLink as Link } from 'react-router-hash-link'

const formattedText = text => {
  return text.split('\n').map(item => {
    return (
      <p className={styles.content} key={item}>
        {item}
      </p>
    )
  })
}

const getClassName = noPadding => {
  return noPadding ? 'abc' : styles.wrapper
}

const getAdditionItem = (item, addition) => {
  if (addition.type === 'hash') {
    const [text, to] = item.split(': ')
    return <Link className={styles.hash} to={`/case-studies#${to}`}>{text}</Link>
  } else {
    return item
  }
}

const Summary = ({ summary, noPadding }) => (
  <div className={getClassName(noPadding)}>
    {summary.tag && (
      <Fragment>
        <h6 className={styles.title}>{summary.tag.title}</h6>
        <hr className={styles.separator} />
        <ul className={styles.tags}>
          {summary.tag.items.map(item => (
            <li key={item} className={styles.tag}>
              {item}
            </li>
          ))}
        </ul>
      </Fragment>
    )}
    <h6 className={styles.title}>{summary.title}</h6>
    <hr className={styles.separator} />
    {formattedText(summary.content)}
    <h6 className={styles.title}>{summary.addition.title}</h6>
    <hr className={styles.separator} />
    {summary.addition.text && formattedText(summary.addition.text)}
    {summary.addition.items && <ul className={styles.list}>
      {summary.addition.items.map(item => (
        <li key={item} className={styles.item}>
          {getAdditionItem(item, summary.addition)}
        </li>
      ))}
    </ul>}
  </div>
)

export default Summary
