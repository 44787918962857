import React from 'react'
import GetInTouchSimple from '../../sections/GetInTouchSimple/GetInTouchSimple'
import SmallHero from '../../sections/SmallHero/SmallHero'
import ContentBlock from '../../sections/ContentBlock/Block'
// import Articles from '../../sections/Articles/Articles'
import PageArticle from '../../components/PageArticle/PageArticle'
import Feedback from './Feedback'
import Tools from './Tools'
import styles from './DesignSprints.module.css'
import phones from './phones.jpg'
import left from './left.svg'
import middle from './middle.svg'
import right from './right.svg'

const links = [
  {
    text: 'Design sprints',
    href: '#design-sprints'
  },
  {
    text: 'Use cases',
    href: '#use-cases'
  }
]

const subtitle =
  'The most resource efficient, rapid and rewarding innovation process there is.'

const sprints = [
  {
    title: 'Pre-Sprint',
    steps: ['Research review', 'Contextual inquiry']
  },
  {
    title: 'Monday',
    steps: ['Define the challenge', 'Produce a mass of solutions'],
    present: true
  },
  {
    title: 'Tuesday',
    steps: [
      'Assess and vote on the best solutions',
      'Storyboard the best solutions'
    ],
    present: true
  },
  {
    title: 'Wednesday',
    steps: ['Build or sketch prototypes', 'Setup user testing']
  },
  {
    title: 'Thursday',
    steps: [
      'Test prototypes with real users',
      'Discuss outcomes and determine next actions'
    ],
    present: true
  }
]

const cb1 = {
  type: 'design-sprints',
  subtitle:
    'Design sprints are a product design methodology born out of Google / Google Ventures. After having run over 50 we’ve made some changes that adapt it perfectly to innovation.',
  text:
    'One of the principles we firmly believe in is contact with the people that are expriencing the problem first hand. So whilst we will review existing research and data it is important that all sprint team members interview users in the context in which they use an existing product or experience some problem.\n' +
    'As a team we then come together to agree on the final definition of the challenge, the criteria for success and any other insights. Before getting into mass solution generation, using various techniques to take ideas beyond the obvious.\n' +
    'Upon deciding on the best solutions, we storyboard them for greater clarity and then work them into prototypes. These prototypes range from sketches to high fidelity prototypes almost undiscernable from the real thing.\n' +
    'Followed by testing with real users and a findings discussion. All within 4 days!\n',
  anchor: 'design-sprints',
  sprints
}

const cb2 = {
  type: 'empty',
  subtitle:
    'At the end of it you can expect high fidelity working prototypes or strategies that have been tested with real users and involved a range of stakeholders.',
  text:
    'Of all of the design sprints we’ve run we estimate that 8 out of 10 have had real products or services piloted as a result, with many still existing in the world today.\n' +
    'From SaaS to insurance, FMCG, tertiary education and mining we believe that any solution can and should be prototyped and tested with real end users. And that this physical, personal contact with end users of products and services is what company founders univerally have but most corporate innovators neglect.\n'
}

const tools = [
  {
    title: 'Product, service or business model innovation',
    content:
      'Design sprints fast forward a process that can otherwise take months',
    icon: left
  },
  {
    title: 'Application and website design',
    content:
      'Design sprints get to a volume of solutions that are more nuanced than the traditional approach',
    icon: middle
  },
  {
    title: 'Customer experience',
    content:
      'Design sprints for marketing strategy, to customer experience enhancements or complete CX redesigns',
    icon: right
  }
]

const DesignSprints = () => {
  return (
    <PageArticle
      pageName='design-sprints'
      render={(recentArticles, pageArticles, loading) => (
        <>
          <SmallHero title='Design sprints' links={links} subtitle={subtitle} />
          <ContentBlock
            type={cb1.type}
            subtitle={cb1.subtitle}
            text={cb1.text}
            anchor={cb1.anchor}
            sprints={cb1.sprints}
          />
          <div className={styles.backgroundSection} />
          <Tools tools={tools} />
          <img className={styles.backgroundImage} alt='KITFIT' src={phones} />
          <ContentBlock
            type={cb2.type}
            subtitle={cb2.subtitle}
            text={cb2.text}
            anchor={cb2.anchor}
          />
          <Feedback />
          {/* <Articles
            title='Related articles'
            padV
            recentArticles={recentArticles}
            pageArticles={pageArticles}
            loading={loading}
          /> */}
          <GetInTouchSimple title='Got a project that’s right for a sprint?' />
        </>
      )}
    />
  )
}

export default DesignSprints
