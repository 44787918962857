import React from 'react'
import styles from './Articles.module.css'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import Spinner from '../../components/Spinner/Spinner'

const renderLoading = () => {
  return (
    <div className={styles.loading} data-aos='fade-up' data-aos-duration='2000'>
      <Spinner loading />
    </div>
  )
}

const renderContent = props => {
  const { pageArticles, recentArticles } = props

  const article0 = pageArticles[0]
    ? pageArticles[0].data
    : recentArticles[0].data
  const article1 = pageArticles[1]
    ? pageArticles[1].data
    : recentArticles[1].data
  const article2 = pageArticles[2]
    ? pageArticles[2].data
    : recentArticles[2].data

  return (
    <div
      className={props.padV ? `${styles.wrapper} padV` : styles.wrapper}
      id={props.id}
    >
      <div
        className={styles.articles}
        data-aos='fade-up'
        data-aos-duration='2000'
      >
        <h3 className={styles.header}>{props.title}</h3>
        <div className={styles.card1}>
          <ArticleCard
            title={article0.title}
            author={article0.author}
            date={article0.date}
            avatar={article0.avatar.url}
            category={article0.category}
            readTime={article0.read_time}
            url={article0.link.url}
            target={article0.link.target}
          />
        </div>
        <div className={styles.card2}>
          <ArticleCard
            title={article1.title}
            author={article1.author}
            date={article1.date}
            avatar={article1.avatar.url}
            category={article1.category}
            readTime={article1.read_time}
            url={article1.link.url}
            target={article1.link.target}
          />
        </div>
        <div className={styles.card3}>
          <ArticleCard
            title={article2.title}
            author={article2.author}
            date={article2.date}
            avatar={article2.avatar.url}
            category={article2.category}
            readTime={article2.read_time}
            url={article2.link.url}
            target={article2.link.target}
          />
        </div>
      </div>
    </div>
  )
}

const Articles = props =>
  props.loading ? renderLoading() : renderContent(props)

export default Articles
