import React from 'react'
import styles from './Subcontent.module.css'
import { HashLink as Link } from 'react-router-hash-link'

const renderLinks = (links) => {
  if (links) {
    return links.map((link) => {
      return (
        <li key={link.href} className={styles.item}>
          <Link className={styles.link} to={link.href}>
            {link.text}
          </Link>
        </li>
      )
    })
  } else {
    return (
      <>
        <li className={styles.item}>
          <a href='/' className={styles.link}>
            Internet of Things
          </a>
        </li>
        <li className={styles.item}>
          <a href='/' className={styles.link}>
            Blockchain
          </a>
        </li>
        <li className={styles.item}>
          <a href='/' className={styles.link}>
            Chatbots
          </a>
        </li>
        <li className={styles.item}>
          <a href='/' className={styles.link}>
            Machine Learning
          </a>
        </li>
        <li className={styles.item}>
          <a href='/' className={styles.link}>
            FinTech
          </a>
        </li>
      </>
    )
  }
}

const Subcontent = ({ links, info }) => (
  <div className={styles.subcontent}>
    <div className={styles.wrapper}>
      <h6 className={styles.subtitle}>{info || 'Latest'}</h6>
      <ul className={styles.list}>{renderLinks(links)}</ul>
    </div>
  </div>
)

export default Subcontent
