import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

const Footer = (props) => (
  <div className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.navWrapper}>
        <hr className={styles.separator} />
        <Link to='/vision' className={styles.link}>
          Vision
        </Link>
        <Link to='/design-sprints' className={styles.link}>
          Design sprints
        </Link>
        {/* <Link to='/approach' className={styles.link}> */}
        {/* Approach */}
        {/* </Link> */}
        <Link to='/services' className={styles.link}>
          Services
        </Link>
        <Link to='/about' className={styles.link}>
          About
        </Link>
        <Link to='/case-studies' className={styles.link}>
          Case-studies
        </Link>
        {/* <Link to='/articles/1' className={styles.link}> */}
        {/* Articles */}
        {/* </Link> */}
        <hr className={styles.separator} />
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.text}>
          Half Machine is a design sprint & product innovation company. We make
          use of technology to enhance the human experience.
        </p>
      </div>
      <div className={styles.connectWrapper}>
        {/* <a href='tel:+610477205535' className={styles.connect}>
          +61 (0) 477 205535
        </a> */}
        {/* <a href='mailto:hello@halfmachine.io' className={styles.connect}>
          hello@halfmachine.io
        </a> */}
      </div>
      <div className={styles.socialWrapper}>
        <a
          href='https://www.linkedin.com/company/18425901/'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.linkSmall}
        >
          LinkedIn
        </a>
        <a
          href='https://twitter.com/damien__h'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.linkSmall}
        >
          Twitter
        </a>
        <a
          href='https://medium.com/@damien1609'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.linkSmall}
        >
          Medium
        </a>
      </div>
      <div className={styles.copyrightWrapper}>
        <p className={styles.copyright}>© Half Machine. All rights reserved.</p>
      </div>
      <div className={styles.legalWrapper}>
        <Link to='/legal-information' className={styles.linkExtraSmall}>
          Legal information
        </Link>{' '}
      </div>
      <div className={styles.cookieWrapper}>
        <Link to='/cookie' className={styles.linkExtraSmall}>
          Cookie policy
        </Link>
      </div>
    </div>
  </div>
)

export default Footer
