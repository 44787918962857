import Prismic from 'prismic-javascript'

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_PAGE_ARTICLES_REQUEST =
  'articles/FETCH_PAGE_ARTICLES_REQUEST'
export const FETCH_PAGE_ARTICLES_SUCCESS =
  'articles/FETCH_PAGE_ARTICLES_SUCCESS'
export const FETCH_PAGE_ARTICLES_ERROR = 'calculator/FETCH_PAGE_ARTICLES_ERROR'

export const FETCH_SPECIFIC_ARTICLE_REQUEST =
  'articles/FETCH_SPECIFIC_ARTICLE_REQUEST'
export const FETCH_SPECIFIC_ARTICLE_SUCCESS =
  'articles/FETCH_SPECIFIC_ARTICLE_SUCCESS'
export const FETCH_SPECIFIC_ARTICLE_ERROR =
  'calculator/FETCH_SPECIFIC_ARTICLE_ERROR'

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = []

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPageArticlesRequest = (loading = true) => {
  return {
    type: FETCH_PAGE_ARTICLES_REQUEST,
    payload: {
      loading
    }
  }
}

export const fetchPageArticlesSuccess = (uids = [], page) => {
  return {
    type: FETCH_PAGE_ARTICLES_SUCCESS,
    payload: {
      page,
      uids,
      receivedAt: new Date().toISOString()
    }
  }
}

export const fetchPageArticlesError = (errorMessage = '') => {
  return {
    type: FETCH_PAGE_ARTICLES_ERROR,
    payload: {
      data: [null, null, null],
      errorMessage,
      receivedAt: new Date().toISOString()
    }
  }
}

export const fetchSpecificArticleRequest = (loading = true) => {
  return {
    type: FETCH_SPECIFIC_ARTICLE_REQUEST,
    payload: {
      loading
    }
  }
}

export const fetchSpecificArticleSuccess = (article = [], index) => {
  return {
    type: FETCH_SPECIFIC_ARTICLE_SUCCESS,
    payload: {
      article,
      index,
      receivedAt: new Date().toISOString()
    }
  }
}

export const fetchSpecificArticleError = (errorMessage = '') => {
  return {
    type: FETCH_SPECIFIC_ARTICLE_ERROR,
    payload: {
      errorMessage,
      receivedAt: new Date().toISOString()
    }
  }
}

// ------------------------------------
// Specialized Actions
// ------------------------------------

export const fetchPageArticles = page => {
  return (dispatch, getState) => {
    dispatch(fetchPageArticlesRequest(true))
    const apiEndpoint = 'https://halfmachine.prismic.io/api/v2'

    return Prismic.api(apiEndpoint).then(api => {
      api.query(Prismic.Predicates.at('document.type', page)).then(response => {
        if (response && response.results_size !== 0) {
          dispatch(fetchPageArticlesSuccess(response.results[0].data, page))
          dispatch(fetchPageArticlesRequest(false))

          const articleNames = ['article_1', 'article_2', 'article_3']

          articleNames.map((name, index) => {
            if (response.results[0].data[name]) {
              dispatch(fetchSpecificArticleRequest(true))
              return Prismic.api(apiEndpoint).then(api => {
                api
                  .query([
                    Prismic.Predicates.at(
                      'my.article.uid',
                      response.results[0].data[name]
                    )
                  ])
                  .then(response => {
                    if (
                      response &&
                      response.results[0] &&
                      response.results[0].data
                    ) {
                      dispatch(fetchSpecificArticleSuccess(response.results[0], index))
                      dispatch(fetchSpecificArticleRequest(false))
                    } else {
                      dispatch(fetchSpecificArticleSuccess(null, index))
                      dispatch(fetchSpecificArticleError('Could not find article'))
                    }
                  })
              })
            } else {
              dispatch(fetchSpecificArticleSuccess(null, index))
              dispatch(fetchSpecificArticleRequest(false))
              return null
            }
          })
        } else {
          dispatch(fetchPageArticlesRequest(false))
          dispatch(fetchPageArticlesError('Less than 3 articles'))
        }
      })
    })
  }
}

const fillWithSpecific = (state, action) => {
  let newState = { ...state }
  let { article, index } = action.payload

  let data = newState.data || []

  data[index] = article

  return { ...state, data }
}

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAGE_ARTICLES_REQUEST:
      return { ...state, ...action.payload }

    case FETCH_PAGE_ARTICLES_SUCCESS:
      return { ...state, ...action.payload }

    case FETCH_PAGE_ARTICLES_ERROR:
      return { ...state, ...action.payload }

    case FETCH_SPECIFIC_ARTICLE_REQUEST:
      return { ...state, ...action.payload }

    case FETCH_SPECIFIC_ARTICLE_SUCCESS:
      return fillWithSpecific(state, action)

    case FETCH_SPECIFIC_ARTICLE_ERROR:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
