import React from 'react'
import styles from './ArticleCard.module.css'
import dots from './img/dots.svg'

const ArticleCard = ({
  title,
  author,
  date,
  avatar,
  category,
  readTime,
  url,
  target
}) => (
  <a
    className={styles.card}
    href={url}
    target={target}
    rel='noopener noreferrer'
  >
    <hr className={styles.hat} />
    <h4 className={styles.title}>{title}</h4>
    <div className={styles.personWrapper}>
      <img className={styles.avatar} src={avatar} alt={author} />
      <p className={styles.author}>{author}</p>
      <p className={styles.date}>{date}</p>
    </div>
    <hr className={styles.ruler} />
    <div className={styles.infoWrapper}>
      <span className={styles.category}>{category}</span>
      <span className={styles.readTime}>{readTime} min read</span>
      <img className={styles.dots} src={dots} alt='expand' />
    </div>
  </a>
)

export default ArticleCard
