import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import Footer from './sections/Footer/Footer'
import Navigation from './components/Navigation/Navigation'
import FloatingBreakpoint from './components/FloatingBreakpoint/FloatingBreakpoint'
import FOF from './pages/FOF/FOF'
import Vision from './pages/Vision/Vision'
import Services from './pages/Services/Services'
import CaseStudies from './pages/CaseStudies/CaseStudies'
import AboutUs from './pages/AboutUs/AboutUs'
import DesignSprints from './pages/DesignSprints/DesignSprints'
// import Approach from './pages/Approach/Approach'
// import Articles from './pages/Articles/Articles'

class App extends Component {
  render () {
    return (
      <>
        <FloatingBreakpoint />
        <Navigation />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/vision' component={Vision} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/case-studies' component={CaseStudies} />
          <Route exact path='/about' component={AboutUs} />
          <Route exact path='/design-sprints' component={DesignSprints} />
          {/* <Route exact path='/approach' component={Approach} /> */}
          {/* <Route exact path='/articles/:pageID' component={Articles} /> */}
          <Route component={FOF} />
        </Switch>
        <Footer />
      </>
    )
  }
}

export default App
