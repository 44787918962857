import React, { Component } from 'react'
import { fetchRecentArticles } from '../../store/modules/recentArticles'
import { fetchPageArticles } from '../../store/modules/pageArticles'
import { connect } from 'react-redux'

class PageArticle extends Component {
  componentDidMount () {
    const { pageName, fetchPageArticles, fetchRecentArticles } = this.props
    fetchRecentArticles()
    fetchPageArticles(pageName)
  }

  render () {
    const { recentArticles, recentLoading } = this.props
    const pageArticles = this.props.pageArticles.data

    let loading = true

    if (pageArticles && pageArticles.length === 3 && !recentLoading) {
      loading = false
    }

    return <>{this.props.render(recentArticles, pageArticles, loading)}</>
  }
}

const mapStateToProps = (state) => ({
  recentLoading: state.recentArticles.loading,
  recentArticles: state.recentArticles.data,
  pageArticles: state.pageArticles
})

const mapDispatchToProps = {
  fetchRecentArticles,
  fetchPageArticles
}

export default connect(mapStateToProps, mapDispatchToProps)(PageArticle)
