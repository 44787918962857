import Prismic from 'prismic-javascript'

// ------------------------------------
// Constants
// ------------------------------------

export const FETCH_JOBS_REQUEST =
  'jobs/FETCH_JOBS_REQUEST'
export const FETCH_JOBS_SUCCESS =
  'jobs/FETCH_JOBS_SUCCESS'
export const FETCH_JOBS_ERROR =
  'jobs/FETCH_JOBS_ERROR'

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = []

// ------------------------------------
// Actions
// ------------------------------------

export const fetchJobsRequest = (loading = true) => {
  return {
    type: FETCH_JOBS_REQUEST,
    payload: {
      loading
    }
  }
}

export const fetchJobsSuccess = (data = []) => {
  return {
    type: FETCH_JOBS_SUCCESS,
    payload: {
      data,
      receivedAt: new Date().toISOString()
    }
  }
}

export const fetchJobsError = (errorMessage = '') => {
  return {
    type: FETCH_JOBS_ERROR,
    payload: {
      errorMessage,
      receivedAt: new Date().toISOString()
    }
  }
}

// ------------------------------------
// Specialized Actions
// ------------------------------------

export const fetchJobs = () => {
  return (dispatch, getState) => {
    dispatch(fetchJobsRequest(true))
    const apiEndpoint = 'https://halfmachine.prismic.io/api/v2'

    return Prismic.api(apiEndpoint).then(api => {
      api
        .query(Prismic.Predicates.at('document.type', 'job'), {
          orderings: '[document.first_publication_date desc]'
        })
        .then(response => {
          if (response) {
            dispatch(fetchJobsSuccess(response.results))
            dispatch(fetchJobsRequest(false))
          }
          // console.log('response', response)
        })
      // console.log('api', api)
    })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_REQUEST:
      return { ...state, ...action.payload }

    case FETCH_JOBS_SUCCESS:
      return { ...state, ...action.payload }

    case FETCH_JOBS_ERROR:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
