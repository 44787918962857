import React from 'react'
import styles from './CTA.module.css'
import { Link } from 'react-router-dom'
import primary from './dot/primary.svg'
import secondary from './dot/secondary.svg'
import accent from './dot/accent.svg'

const CTA = ({ to, text, main, dot, textOnly, onClick, href, rel }) => {
  let mainClass = null
  let imgSrc = null

  main ? (mainClass = styles[main]) : (mainClass = styles.primaryText)

  if (dot === 'primary') {
    imgSrc = primary
  } else if (dot === 'secondary') {
    imgSrc = secondary
  } else if (dot === 'accent') {
    imgSrc = accent
  } else {
    imgSrc = primary
  }
  if (textOnly) {
    return (
      <span className={mainClass} onClick={onClick}>
        {text}
        <img className={styles.dot} src={imgSrc} alt='dot' />
      </span>
    )
  } else if (rel && href) {
    return (
      <a
        className={mainClass}
        href={href}
        rel='noopener noreferrer'
        target='_blank'
      >
        {text}
        <img className={styles.dot} src={imgSrc} alt='dot' />
      </a>
    )
  } else if (href) {
    return (
      <a className={mainClass} href={href}>
        {text}
        <img className={styles.dot} src={imgSrc} alt='dot' />
      </a>
    )
  } else {
    return (
      <Link className={mainClass} to={to}>
        {text}
        <img className={styles.dot} src={imgSrc} alt='dot' />
      </Link>
    )
  }
}

export default CTA
