import React from 'react'
import styles from './FloatingBreakpoint.module.css'

const FloatingBreakpoint = props => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div className={styles.floatingBreakpoint}>
        <p className={styles.xs}>xs</p>
        <p className={styles.sm}>sm</p>
        <p className={styles.md}>md</p>
        <p className={styles.lg}>lg</p>
        <p className={styles.xl}>xl</p>
      </div>
    )
  } else {
    return null
  }
}

export default FloatingBreakpoint
