import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import rootReducer from './modules/rootReducer'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-115803641-1')

export const history = createBrowserHistory()

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname + location.search })
  ReactGA.pageview(location.pathname + location.search)
})

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
