import React, { Fragment } from 'react'
import GetInTouch from '../../sections/GetInTouch/GetInTouch'
import SmallHero from '../../sections/SmallHero/SmallHero'
import ContentBlock from '../../sections/ContentBlock/Block'
import Articles from '../../sections/Articles/Articles'
import serviceDesignLarge from './service-design-large.jpg'
import serviceDesignSmall from './service-design-small.jpg'
import cora from './cora.jpg'
// import classrooms from './classrooms.png'
import PageArticle from '../../components/PageArticle/PageArticle'
import watson from './watson.png'

const links = [
  {
    text: 'Design sprints',
    href: '#design-sprints'
  },
  {
    text: 'Cora bot',
    href: '#cora-bot'
  },
  {
    text: 'PSFK Watson',
    href: '#psfk-watson'
  }
]
const cb1 = {
  type: 'tag',
  title: 'Designing a new mutality',
  titleAddition: 'Service design',
  summary: {
    tag: {
      title: 'Tools and technologies used',
      items: ['Story Mapping', 'Sprints']
    },
    title: 'The challenge',
    content:
      'Answer critical business questions through design, prototyping, and testing ideas with customers over five days.',
    addition: {
      title: 'Key outcomes',
      items: ['TBA']
    }
  },
  text:
    'Using GV\'s design sprint methodology we created a framework to help TAL, Australia\'s leading life insurance specialist, answer critical business questions through rapid prototyping and user testing. The process helps the business spark innovation, encourage user-centered thinking and align the organisation under a shared vision - to be at the forefront of innovation within the insurance industry and the driving force behind a "new mutuality".\n' +
    'Design sprints are a highly effective way of formulating & validating solutions to the right problems. They allow teams to move from high level strategy to thoughtful solutions and even implementation prototypes in a short amount of time.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: serviceDesignLarge,
  imageSmall: serviceDesignSmall,
  anchor: 'design-sprints'
}

const cb2 = {
  type: 'tag',
  title: 'Cora. Your personal injury and illness recover companion.',
  titleAddition: 'Product Design',
  summary: {
    tag: {
      title: 'Technologies used',
      items: ['Chatbot', 'Messenger', 'API.ai']
    },
    title: 'The challenge',
    content:
      'Of the half a million or so Australians hospitalised as a result of traumatic injury, one-in-three* will experience depression after three months. How may we reduce this?\n' +
      '*Bryant RA, O’Donnell ML et al (2010). ‘The psychiatric sequelae of traumatic injury.’ American Journal of Psychiatry 167(3): 312-320.',
    addition: {
      title: 'Key outcomes',
      text: 'Cora is currently pilot platform, gathering information and insights with a view to extend and continually develop the program.'
    }
  },
  text:
    'Coping with a serious injury and its treatments can be challenging enough, but if you experience mental health problems as well, it can be very difficult to work out how to manage – both emotionally and practically.\n' +
    'Cora is a community based support platform who’s ultimate vision is to provide scalable support via an AI driven chat application.\n' +
    'Cheerleader, counsellor and coach, Cora is designed to help users master their recovery by delivering simple, easy to follow coping strategies accessible anywhere, anytime.\n' +
    'Backed by a panel of experts, Cora brings together the extraordinary stories of real people, the knowledge of psychologists and experience of healthcare experts to create an inspiring, informative and relevant back-to-health program that is designed to aide users through their recovery journey and as easy to use as texting.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: cora,
  anchor: 'cora-bot'
}

const cb3 = {
  type: 'tag',
  title: 'PSFK and Watson. Data for good.',
  titleAddition: 'Service design',
  summary: {
    tag: {
      title: 'Tools and technologies used',
      items: ['Machine learning', 'Behaviour design']
    },
    title: 'The challenge',
    content:
      'How might we use the data Optus has access to in order to identify and help teens with depression?',
    addition: {
      title: 'Key outcomes',
      items: ['Sandboxed program', 'Global innovation challenge Top 8']
    }
  },
  text:
    'Mobile phone companies have a multitude of vectors of data that can be used to model behavioural characteristics that indicate depression. From how often people contact others, to time of day, geolocation, Apps used in what frequencies, the number of different contacts communicated to and what services reached out to.\n' +
    'We devised a system that used this data to identify teens with depressive characteristics, giving us the ability to target them with support content via advertising networks. As well as identifying key stakeholders.',
  cta: {
    href: 'https://half-machine-contact-page.webflow.io/',
    text: 'Get in touch',
    rel: true
  },
  image: watson,
  anchor: 'psfk-watson'
}

const CaseStudies = () => {
  return (
    <PageArticle
      pageName='case-studies'
      render={(recentArticles, pageArticles, loading) => (
        <Fragment>
          <SmallHero subtitle='Learn what works. Learn what doesn’t. Iterate.' title='Case studies' links={links} />
          <ContentBlock
            type={cb1.type}
            title={cb1.title}
            titleAddition={cb1.titleAddition}
            summary={cb1.summary}
            text={cb1.text}
            cta={cb1.cta}
            image={cb1.image}
            imageSmall={cb1.imageSmall}
            anchor={cb1.anchor}
            video={cb1.video}
          />
          <ContentBlock
            type={cb2.type}
            title={cb2.title}
            titleAddition={cb2.titleAddition}
            summary={cb2.summary}
            text={cb2.text}
            cta={cb2.cta}
            image={cb2.image}
            imageSmall={cb2.imageSmall}
            anchor={cb2.anchor}
            video={cb2.video}
          />
          <ContentBlock
            type={cb3.type}
            title={cb3.title}
            titleAddition={cb3.titleAddition}
            summary={cb3.summary}
            text={cb3.text}
            cta={cb3.cta}
            image={cb3.image}
            imageSmall={cb3.imageSmall}
            anchor={cb3.anchor}
            video={cb3.video}
          />
          <Articles
            title='Related articles'
            padV
            recentArticles={recentArticles}
            pageArticles={pageArticles}
            loading={loading}
          />
          <GetInTouch />
        </Fragment>
      )}
    />
  )
}

export default CaseStudies
