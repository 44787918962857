import React, { Component } from 'react'
import img from './svg/logo.svg'
import styles from './Logo.module.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { close, open } from '../../store/modules/menu'

class Logo extends Component {
  handleOpen = () => {
    const { close } = this.props
    if (this.props.opened) {
      close()
    }
  }

  render () {
    return (
      <Link to='/' onClick={this.handleOpen}>
        <img className={styles.logo} src={img} alt='Half Machine Logo' />
      </Link>
    )
  }
}

const mapStateToProps = state => ({
  opened: state.menu.opened
})

const mapDispatchToProps = {
  open,
  close
}

export default connect(mapStateToProps, mapDispatchToProps)(Logo)
