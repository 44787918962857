import React from 'react'
import styles from './Subtitle.module.css'

const Subtitle = () => (
  <h5 className={styles.subtitle}>
    Half Machine is a design sprint & product innovation company. We make use of technology to enhance the human experience.
  </h5>
)

export default Subtitle
