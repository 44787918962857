import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate (prevProps) {
    const prevPath = prevProps.location.pathname
    const nextPath = this.props.location.pathname
    const articleChange =
      prevPath.startsWith('/articles') && nextPath.startsWith('/articles')
    if (!prevPath.startsWith('/articles')) {
      window.scrollTo(0, 0)
    } else if (!articleChange && nextPath !== prevPath) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
