import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store'
import 'normalize.css'
import './fonts/Simplon/Simplon.css'
import './fonts/Roboto/Roboto.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './styles/index.css'
import './styles/grid.css'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'

AOS.init()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// registerServiceWorker()
