import React, { Component } from 'react'
import styles from './Menu.module.css'

class Menu extends Component {
  handleClick = () => {
    let { opened, onClick } = this.props
    onClick(!opened)
  }

  render () {
    const { opened } = this.props
    return (
      <button
        className={
          opened
            ? styles.opened
            : styles.menu
        }
        type='button'
        onClick={this.handleClick}
      >
        <span className={styles.box}>
          <span className={styles.inner} />
        </span>
      </button>
    )
  }
}

export default Menu
