/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react'
import styles from './Block.module.css'
import CTA from '../../components/CTA/CTA'
import Title from './Title'
import Subtitle from './Subtitle'
import Text from './Text'
import List from './List'
import Image from './Image'
import AnimateHeight from 'react-animate-height'
import Media from 'react-media'
import Summary from './Summary'
import Video from './Video'
import Sprints from './Sprints'
import { Portal } from 'react-portal'

class Block extends Component {
  state = {
    height: 0,
    showVideo: false
  }

  handleCTAClick = () => {
    const currentHeight = this.state.height

    if (currentHeight === 0) {
      this.setState(() => ({ height: 'auto' }))
    } else {
      this.setState(() => ({ height: 0 }))
    }
  }

  renderText = (matches) => {
    const {
      text,
      cta,
      articles,
      loading,
      type,
      pageID,
      totalPages
    } = this.props
    if (matches) {
      return (
        <>
          <div className={styles.textWrapper}>
            {type === 'articles' ? (
              <List
                articles={articles}
                loading={loading}
                pageID={pageID}
                totalPages={totalPages}
              />
            ) : (
              <Text text={text} />
            )}
          </div>
          {cta && (
            <div className={styles.ctaWrapper}>
              <CTA
                to={cta.to}
                rel={cta.rel}
                href={cta.href}
                text={cta.text}
                onClick={this.handleCTAClick}
              />
            </div>
          )}
        </>
      )
    } else {
      return (
        <>
          <AnimateHeight
            duration={500}
            height={this.state.height}
            className={styles.textWrapper}
          >
            {type === 'articles' ? (
              <List
                articles={articles}
                loading={loading}
                pageID={pageID}
                totalPages={totalPages}
              />
            ) : (
              <Text text={text} />
            )}
          </AnimateHeight>
          {cta && <div className={styles.ctaWrapper}>{this.renderCTA()}</div>}
        </>
      )
    }
  }

  renderCTA = () => {
    const { cta } = this.props
    if (this.state.height === 0) {
      return <CTA textOnly text='Read more' onClick={this.handleCTAClick} />
    } else {
      return (
        <CTA
          to={cta.to}
          rel={cta.rel}
          href={cta.href}
          text={cta.text}
          onClick={this.handleCTAClick}
        />
      )
    }
  }

  renderAddition = () => {
    const { type, subtitle, summary } = this.props
    if (
      type === 'simple' ||
      type === 'articles' ||
      type === 'design-sprints' ||
      type === 'empty'
    ) {
      return <Subtitle subtitle={subtitle} />
    }
    if (type === 'summary' || type === 'tag') {
      return <Summary summary={summary} />
    } else {
      return null
    }
  }

  handleVideoClick = () => {
    const currentShowVideo = this.state.showVideo

    if (currentShowVideo === true) {
      this.setState(() => ({ showVideo: false }))
    } else {
      this.setState(() => ({ showVideo: true }))
    }
  }

  renderHeader = () => {
    const {
      title,
      titleAddition,
      image,
      imageSmall,
      video,
      type,
      sprints
    } = this.props

    if (this.state.showVideo) {
      return (
        <Media query='(min-width: 576px)'>
          {(matches) =>
            matches ? (
              <>
                <Image title={title} image={image} />
                <Title
                  title={title}
                  titleAddition={titleAddition}
                  video={video}
                  onVideoClick={this.handleVideoClick}
                />
                <Portal node={document && document.getElementById('root')}>
                  <Video
                    video={video}
                    onVideoClick={this.handleVideoClick}
                    layout='desktop'
                  />
                </Portal>
              </>
            ) : (
              <Video
                video={video}
                onVideoClick={this.handleVideoClick}
                layout='mobile'
              />
            )}
        </Media>
      )
    } else if (type === 'design-sprints') {
      return <Sprints sprints={sprints} />
    } else if (type === 'empty') {
      return null
    } else {
      return (
        <>
          <Image title={title} image={image} imageSmall={imageSmall} />
          <Title
            title={title}
            titleAddition={titleAddition}
            video={video}
            onVideoClick={this.handleVideoClick}
          />
        </>
      )
    }
  }

  render () {
    const { anchor, type } = this.props
    return (
      <>
        <span className={styles.anchor} id={anchor} />
        <div
          className={styles.blockWrapper}
          data-aos='fade-up'
          data-aos-duration='2000'
        >
          <div className={type === 'empty' ? styles.emptyBlock : styles.block}>
            {this.renderHeader()}
            {this.renderAddition()}
            <Media query='(min-width: 576px)'>
              {(matches) => this.renderText(matches)}
            </Media>
          </div>
        </div>
      </>
    )
  }
}

export default Block
