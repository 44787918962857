import React from 'react'
import styles from './Content.module.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { close } from '../../store/modules/menu.js'
import CTA from '../CTA/CTA'

const Content = (props) => (
  <div className={styles.content}>
    <div className={styles.linksWrapper}>
      <Link to='/vision' onClick={() => props.close()} className={styles.link}>
        Vision
      </Link>
      <Link
        to='/design-sprints'
        onClick={() => props.close()}
        className={styles.link}
      >
        Design sprints
      </Link>
      {/* <Link */}
      {/* to='/approach' */}
      {/* onClick={() => props.close()} */}
      {/* className={styles.link} */}
      {/* > */}
      {/* Approach */}
      {/* </Link> */}
      <Link
        to='/services'
        onClick={() => props.close()}
        className={styles.link}
      >
        Services
      </Link>
      <Link to='/about' onClick={() => props.close()} className={styles.link}>
        About
      </Link>
      <Link
        to='/case-studies'
        onClick={() => props.close()}
        className={styles.link}
      >
        Case-studies
      </Link>
      {/* <Link */}
      {/* to='/articles/1' */}
      {/* onClick={() => props.close()} */}
      {/* className={styles.link} */}
      {/* > */}
      {/* Articles */}
      {/* </Link> */}
    </div>
    <div className={styles.textWrapper}>
      <p className={styles.text}>
        Half Machine is a design sprint & product innovation company. We make
        use of technology to enhance the human experience.
      </p>
      <span onClick={() => props.close()} className={styles.cta}>
        <CTA
          text='Get in touch'
          main='secondaryText'
          rel
          href='https://half-machine-contact-page.webflow.io/'
        />
      </span>
    </div>
  </div>
)

const mapDispatchToProps = {
  close
}

export default connect(null, mapDispatchToProps)(Content)
