import React from 'react'
import styles from './ImageCard.module.css'
import { HashLink as Link } from 'react-router-hash-link'

const ImageCard = ({ title, subtitle, img, content, link }) => {
  if (content && link) {
    return (
      <a href={link} className={styles.contentCard}>
        <img src={img} alt={title} className={styles.img} />
        <h4 className={styles.contentTitle}>{title}</h4>
        <p className={styles.contentSubtitle}>{subtitle}</p>
        <p className={styles.content}>{content}</p>
      </a>
    )
  } else if (link) {
    return (
      <Link to={link} className={styles.standardCard}>
        <img src={img} alt={title} className={styles.img} />
        <h4 className={styles.standardTitle}>{title}</h4>
        <p className={styles.standardSubtitle}>{subtitle}</p>
      </Link>
    )
  } else {
    return (
      <div className={styles.standardCard}>
        <img src={img} alt={title} className={styles.img} />
        <h4 className={styles.standardTitle}>{title}</h4>
        <p className={styles.standardSubtitle}>{subtitle}</p>
      </div>
    )
  }
}

export default ImageCard
