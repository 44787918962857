import React from 'react'
import styles from './Subtitle.module.css'

const Subtitle = ({ subtitle }) => (
  <h4 className={styles.subtitle}>
    {subtitle}
  </h4>
)

export default Subtitle
