import React from 'react'
import styles from './Content.module.css'
import CTA from '../CTA/CTA'

const formattedText = (text) => {
  return text.split('\n').map((item) => {
    return (
      <p className={styles.content} key={item}>
        {item}
      </p>
    )
  })
}

const Content = ({ header, text, addition, cta }) => (
  <div className={styles.wrapper}>
    {addition && <h6 className={styles.addition}>{addition}</h6>}
    <h3 className={styles.header}>{header}</h3>
    {formattedText(text)}
    {cta ? (
      <CTA to={cta.to} text={cta.text} />
    ) : (
      <CTA
        href='https://half-machine-contact-page.webflow.io/'
        text='Get in touch'
        rel
      />
    )}
  </div>
)

export default Content
