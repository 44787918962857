import React from 'react'
import ImageCard from '../../components/ImageCard/ImageCard'
import styles from './Section.module.css'
import CTA from '../../components/CTA/CTA'

const Section = ({ data, cta, transparent, anchor, header }) => (
  <div
    className={transparent ? styles.transparentWrapper : styles.normalWrapper}
  >
    <div
      data-aos='fade-up'
      data-aos-duration='2000'
      className={styles.services}
    >
      { header && <p className={styles.header}>{header}</p> }
      <div className={styles.card1} id={anchor}>
        <ImageCard
          title={data[0].title}
          subtitle={data[0].subtitle}
          content={data[0].content}
          link={data[0].link}
          img={data[0].image}
        />
      </div>
      <div className={styles.card2}>
        <ImageCard
          title={data[1].title}
          subtitle={data[1].subtitle}
          content={data[1].content}
          link={data[1].link}
          img={data[1].image}
        />
      </div>
      {data[2] && (
        <div className={styles.card3}>
          <ImageCard
            title={data[2].title}
            subtitle={data[2].subtitle}
            content={data[2].content}
            link={data[2].link}
            img={data[2].image}
          />
        </div>
      )}
      {cta && (
        <div className={styles.cta}>
          <CTA to='/services' text='View our services' />
        </div>
      )}
    </div>
  </div>
)

export default Section
