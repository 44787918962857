import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import menu from './menu'
import recentArticles from './recentArticles'
import pageArticles from './pageArticles'
import allArticles from './allArticles'
import jobs from './jobs'

export default combineReducers({
  router: routerReducer,
  menu,
  recentArticles,
  pageArticles,
  allArticles,
  jobs
})
