import React from 'react'
import styles from './Image.module.css'
import imageDefault from './images/image-huge.jpeg'
import imageAlternative from './images/lego.jpg'
import imageCora from './images/cora.jpg'
import imageSprints from './images/sprints.jpg'

const getImage = image => {
  if (image === 'alternative') {
    return imageAlternative
  } else if (image === 'cora') {
    return imageCora
  } else if (image === 'sprints') {
    return imageSprints
  } else {
    return imageDefault
  }
}

const Image = ({ image }) => (
  <img src={getImage(image)} alt='Our approach' className={styles.image} />
)

export default Image
