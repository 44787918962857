import React from 'react'
import styles from './GetInTouch.module.css'

const GetInTouch = ({ title }) => (
  <div className={styles.wrapper}>
    <h3 className={styles.header}>
      {title || "Let's build our future together"}
    </h3>
    <a
      className={styles.button}
      href='https://half-machine-contact-page.webflow.io/'
      rel='noopener noreferrer'
      target='_blank'
    >
      Get in touch
    </a>
  </div>
)

export default GetInTouch
